@import '../base/variables';
@import '../base/mixins';


.subtotal{
    
    display: flex;
    min-width: 15rem;
    background-color: $dark-blue;
    flex-direction: column;
    padding: .5rem 3.5rem;

    @include respond-to('phone') {
       flex-grow: 1;
    }


    &__currency{
        color: $white;
        font-size: 1rem;
        line-height: 1.2rem;
        font-weight: 700;
    }

    &__amount{
        color: $white;
        font-size: 2.8rem;
        font-weight: 700;
        text-align: center;
        line-height: 2.6rem;
    }

    &__label{
        color: #C2CAD4;
        font-size: 1rem;
        text-align: center;
    }

}

