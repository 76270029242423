@import '../base/variables';
@import '../base/mixins';


.choose-workshop-page{
    display: flex;
    flex-direction: column;
}



@include respond-to('phone'){
    .heading{
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    .choose-workshop-page{
        min-height: 100vh;
    }
}