@import '../base/variables';

.header-workshops{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 0;
    border-bottom:1px solid #D7DBE2;
    box-shadow: 9px 9px 19px 0px rgba(155, 163, 178, 0.07);


    img{
        cursor: pointer;
    }
}

.content{ 
    margin-top: 8rem;
    padding: 0 8rem; 
    margin-bottom: 12rem;

    @media (max-width: 768px) {
        margin-top: 5rem;
        padding: 0 1rem;
    }


    .title{
        font-size: 3.8rem;
        margin-bottom: 5rem;
        color: $dark-blue;


        @media (max-width: 768px) {
            font-size: 3rem;
            padding-left: 2.5rem;
            margin-bottom: 3rem;
        }

    }
}
