@import "../base//variables";
@import "../base//mixins";

.verification-page{
    @include center-page;

    &__timer{
        color: $light-blue;
        font-size: 1.5rem;
        font-weight: $font-bold;
        line-height: $line-height;
        text-transform: capitalize;
        margin-top: 5rem;
        margin-bottom: 2rem;
    }

    &__button{
        min-width: 30rem;
        min-height: 4rem;
        cursor: pointer;
        border-radius: 0.5rem;
        background: $light-blue;
        color: $white;
        padding: 0.75rem 1.5rem;
        border: transparent;
        font-size: $title-size;
        line-height: $line-height;
        font-weight: $font-bold;
        font-family: $primary-font;
    }
}