.workshop-grid {
    display: grid;
    grid-template-columns: repeat(3, minmax(240px, 1fr));
    gap: 4rem;
    padding: 2rem;
    list-style: none;
  
    
    @media (max-width: 768px) {
      grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    }
  
    &__item {
      display: flex;
      flex-direction: column;
      align-items: center;
      //box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
      transition: transform 0.2s;
      cursor: pointer;

  
      &:hover {
        transform: translateY(-5px);
  
        &::after {
          content: '→'; 
          position: absolute;
          right: 10px;
          top: calc(100% - 20px);
          font-size: 24px; 
          color: $light-blue; 
          opacity: 1; 
          transition: opacity 0.3s ease-in-out;
        }
  
        .workshop-grid__item-name {
          color: $light-blue; 
        }
      }
    }
  
    &__item-image {
      width: 100%; 
      height: auto; 
    }
  
    &__item-name {
      padding-top: 10px;
      font-size: 16px; 
      text-align: left;
      font-family: 'quicksand', sans-serif; 
      color: #333; 
      width: 100%; 
      overflow: hidden; 
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  