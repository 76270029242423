

.book-date-page{


    .heading{
        margin-bottom: 2rem;
    }

    display: flex;
    flex-direction: column;
    justify-content: center;

    &__calendar{
        display: flex;
        justify-content: center;
        align-items: center;
    }
}