@import "./variables";

@mixin center-page{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@mixin radio{
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 0.75rem;
    border: 1px solid $light-gray;
    background: #FFF;
    cursor: pointer;
}


@mixin respond-to($breakpoint) {
    @if map-has-key($breakpoints, $breakpoint) {
        @media (max-width: map-get($breakpoints, $breakpoint)) {
            @content;
        }
    } @else {
        @warn 'Invalid breakpoint: #{$breakpoint}.';
    }
}
