@import '../base/variables';


.category-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(356px, 1fr));
    gap: 4rem;
    padding: 2rem;
    list-style: none;
  
    @media (max-width: 768px) {
      grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    }
  
    &__item {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      transition: transform 0.2s;
  
      &:hover {
        transform: translateY(-5px);
  
        &::after {
          content: '→'; 
          position: absolute;
          right: 10px;
          top: calc(100% - 20px);
          font-size: 24px; 
          color: $light-blue; 
          opacity: 1; 
          transition: opacity 0.3s ease-in-out;
        }
  
        .category-grid__name {
          color: $light-blue; 
        }
      }
    }
  
    &__image {
      width: 100%;
      height: auto;
    }
  
    &__name {
      align-self: flex-start;
      font-family: 'quicksand', sans-serif;
      padding-top: 1rem;
      font-size: 2.2rem;
      text-align: left;
      color: #333;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      transition: color 0.3s ease-in-out;
      position: relative; 
    }
  }
  