

.success-page{

    
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;


    &__btn{
        display: flex;
        border-radius: 0.5rem;
        background: #0059FC;
        margin: 0 auto;
        color: #FFFFFF;
        cursor: pointer;
        padding: 1rem;
        border: transparent;
        font-size: 1.5rem;
        line-height: 1.5rem;
        font-weight: 400;
        font-family: "Quicksand", sans-serif;
        }
        
}