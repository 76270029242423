@import '../base/variables';
@import '../base/mixins';


.registration-form{

    display: flex;
    flex-direction: column;
    gap: 1rem;
    min-width: 50rem;

    &__label{
        color: $dark-blue;
        font-size: $title-size;
        font-weight: 500;
        line-height: $line-height;

        &--checkbox{
            display: flex;
            color: $light-gray;
            font-weight: 500;
            font-size: $title-size;
        }
    }

    &__input{
        display: flex;
        width: 100%;
        height: 4rem;
        border-radius: .25rem;
        border: 1px solid $grey;
        padding: 1rem;
        background: $white;

        font-size: $title-size;
        font-weight: 500;
        line-height: $line-height;
        color: $dark-blue;
        outline: none;

        &:focus{
            border: 2px solid $dark-blue;
        }

        &::placeholder{
            color: $grey;
            font-size: $title-size;
        }

        &--checkbox{
            width: 1.5rem;
            height: 1.5rem;
            margin-right: .5rem;
            
            border: 1px solid $grey;
            background: #FFF;
            cursor: pointer;
        }

        &--terms{
            color: $light-blue;
            padding: 0rem 0.5rem;

        }
    }

    &__button{
        display: none;
    }
}


@include respond-to('phone'){

    .registration-form{
        min-width: 0;
    }
}


/*.flag-dropdown{

    background-color: transparent;
    border: none;
}

.react-tel-input .form-control{

width:100%
    border: 1px solid $grey;
}

.react-tel-input .selected-flag .arrow {


}*/