@import "variables";

*,
*::after,
*::before,
#root {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html{
    font-size: 62.5%;
}

body{
    font-family: $primary-font;
}

.app{
   
    min-height: 100vh;
}