@import '../base/variables';
@import '../base/mixins';

.heading{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 8rem;
    margin-bottom: 5rem;


    &__text{
        font-size: $heading-size;
        color: $dark-blue;
        font-weight: 700;
    }

    &__subtext{
        font-size: 1.5rem;
        color: $light-gray;
        font-weight: 400;
        line-height: $line-height;
        margin-top: .5rem;
    }
}



