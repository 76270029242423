
//fonts
$primary-font: "Quicksand", sans-serif;
$secondary-font: "Montserrat", sans-serif;
$line-height: 1.5rem;


//colors
$light-blue: #0059FC;
$dark-blue: #14253F;
$white: #FFFFFF;
$light-gray: #70829A;
$paragraph-gray: #334D6E;
$grey: #99A6B7;
$green: #00A729;
$summary-bg: #F4F7F9;

//size
$heading-size: 3rem;
$title-size: 1.25rem;

$font-normal: 400;
$font-bold: 600;

//padding
$item-padding: 1.25rem;
$text-padding: .5rem;

//border-radius
$border-radius: 0.62rem;

//breakpoints

$breakpoints: (
    'phone': 480px,
    'tablet': 768px,
    'desktop': 1024px,
    'large-desktop': 1280px,
);
