@import '../base/mixins';
@import '../base/variables'; 

.book-options-page{

    @include center-page;


    &-wrapper{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 2rem;
        max-width: 90rem;
        margin: 0 auto;

        
    }
    
}

.booking-info {
    
    margin-top: 20px;
    padding: 10px 0;
    text-align: center;
    max-width: 90rem;
    

    p {
      margin: 0;
      font-size: 14px;
      line-height: 1.5rem;
      color: #333;
    }
}

@include respond-to('phone'){
    .book-options-page{
        &-wrapper{
            grid-template-columns: 1fr;
            margin: 1rem;
            max-width: none;
           
        }

    }

    .heading__subtext{
        margin: 1rem;
    }

}
