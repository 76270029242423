@import '../base/variables';
@import '../base/mixins';



.book-option{
    display: flex;
    flex-direction: column;
    position: relative;
    border-radius: $border-radius;
    border: 1px solid #CCC;
    background: #FFF;
    padding: $item-padding;
    flex-grow: 1;


    &__label{
        display: flex;
    }



    &__heading{
        display: flex;
        flex-direction: row;
        gap: $text-padding;

        &--input{
            @include radio;
        }

        &--text{
            color: $dark-blue;
            font-size: 1.7rem;
            line-height: $line-height;
            font-weight: 600;
        }

        &--price{
                
            margin-left: $text-padding;
          
        }
    }

    &__discount{
        position: absolute;
        left: 78%;
        display: inline-flex;
        height: 1.5rem;
        padding: 0.125rem 0.5rem;
        justify-content: center;
        align-items: center;
        gap: 0.625rem;
        flex-shrink: 0;
        border-radius: 3.125rem;
        color: $green;
        font-size: 1rem;
        font-weight: 500;
        font-family: $secondary-font;
        background: rgba(0, 167, 41, 0.12);
    }

    &__description{
        display: flex;
        position: relative;
        flex-direction: column;
        padding-left: 1.8rem;
        padding-top: 0.5rem;


        &--cancel{  
            color: $grey;
            font-size: 1rem;
        }

        &--desc{
            padding-top: 2rem;
            color: $paragraph-gray;
            line-height: $line-height;
            font-weight: 400;
            font-size: $title-size;


            a{
                color: $paragraph-gray;
               
            }
        }

        &--tooltip{
            display: flex;
            padding-top: 2rem;

            &--checkbox{
                margin-right: $text-padding;
            }

            p{
                color: $dark-blue;
                font-size: 1rem;
                font-weight: 500;
            }

            &--text{
                visibility: hidden;
                display: flex;
                flex-direction: column;
                max-width: 15rem;
                background-color: $dark-blue;
                color: $white;
                font-size: 1rem;
                padding: 0.25rem 0.5rem;
                align-items: center;

                position: absolute;
                z-index: 1;
                left: 20%;
                bottom: -12%;

                &--link{
                    color: $white;
                    font-size: 1rem;

                }
            }

            &:hover &--text{
                visibility: visible;
            }
        }
    }
   
}

.selected{
    border: 2px solid $light-blue;
}


