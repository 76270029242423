@import "../base/mixins";
@import "../base/variables";


.booking-details{
    
    @include center-page;

    margin-top: 5rem;
    margin-bottom: 8rem;


    &__heading{
        color: $dark-blue;
        font-size: 2rem;
        font-weight: 600;
        margin: 2rem 0rem;
    }

    &__summary{
        padding: 2rem 1.5rem;
        border-radius: 0.625rem;
        background: $summary-bg;
        min-width: 50rem;

        &--item{
            display: flex;
            justify-content: space-between;
            gap: 25rem;
        }

        &--description{
            color: $dark-blue;
            font-size: 1.5rem;
            font-weight: $font-bold;
        }

        &--price{
            color: $dark-blue;
            font-size: 1.5rem;
            font-weight: $font-normal;
        }

        &--label{
           font-weight: $font-bold;
           font-size: 1.6rem;
           line-height: $line-height;
        }

        &--amount{
            font-weight: $font-bold;
            font-size: 1.75rem;
            line-height: $line-height;
        }
    }

    &__personal{
        padding: 2rem 1.5rem;
        border-radius: 0.625rem;
        background: $summary-bg;
        min-width: 50rem;
        display: flex;
        flex-direction: column;

        &--item{
            font-size: 1.5rem;
            color: $dark-blue;
            font-weight: $font-normal;
            line-height: $line-height;
            padding: 2rem;
            border-bottom: 1px solid rgba(188, 194, 200, 0.5);

            &:last-child{
                border-bottom: none;
                padding-bottom: 1rem;
            }

            &:first-child{
               padding-top: 1rem;
            }
        }
    }

    &__date{
        margin: 1rem 0rem;
        color: $light-blue;
        font-size: $title-size;
        font-weight: $font-normal;
        line-height: $line-height;
        border-bottom: 1px solid rgb(188, 194, 200);
        padding-bottom: 1.4rem;
    }

    &__total{


        &--label{
            color: $dark-blue;
            font-size: $title-size;
            font-weight: $font-bold;
        }

        &--price{
            color: $dark-blue;
            font-size: 1.5rem;
            font-weight: $font-bold;
            line-height: $line-height;
        }
    }
}


@include respond-to('phone') {

    .summary-page{
        min-height: 100vh;
    }



    .booking-details{

        margin-bottom: 1rem;


        &__heading{
            
            &__text{
                margin: 1rem;
            }
        }

        &__summary{
            min-width: 0;
            width: 90%;

            &--item{
                gap: 0;
            }
        }

        &__personal{
            min-width: 90%;
        }
    }
}