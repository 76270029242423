@import '../base/variables';

.calendar {

    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background-color: #fff;
    border-radius: 8px;
    max-width: 90rem; 
    margin: 20px auto;
    overflow: hidden;
    
    &__days-of-week{
      display: grid;
      grid-template-columns: repeat(7, 1fr); 
      margin-top: 2rem;
      text-align: center;

      &--day{
        color: $light-gray;
        font-weight: 400;
        font-size: 1rem;
      }
    }


    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 2rem 1rem;
      font-size: $title-size;
  
      &__button {
          border: none;
          background-color: transparent;
          cursor: pointer;

          &.disabled {
            color: #70829A;
            cursor: not-allowed;
    
            svg {
              fill: #70829A;
            }
          }
    
          &.enabled {
            color: $light-blue;
            cursor: pointer;
    
            svg {
              fill: $light-blue;
            }
          }

      }

      &__month{
        color: $light-gray;
        font-size: $title-size;

      }
  
      &__month-year {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        font-size: 1.5em;
        font-weight: 400;
        color: $dark-blue;
        line-height: $line-height;
      }

      &__year{
        font-size: 1rem;
        align-self: center;
      }

    }
  
    &__grid {
      display: grid;
      grid-template-columns: repeat(7, 1fr); // 7 days a week
      text-align: center;
      gap: .5rem;
      padding: 1rem;
      
      .day {
        border: 1px solid $light-gray;
        padding: 1rem;
        border-radius: $border-radius;
        cursor: pointer; 

        &.selected {
          background-color: $light-blue;
          .day__date, .day__price {
            color: $white;
          }
        }

        &.unavailable{
          color: #C2CAD4;
          border: 1px solid #C2CAD4;
        
        
          .day__date, .day__price{
            color: #C2CAD4;
            cursor: default;
            pointer-events: none;
          }
        }

        &:hover {
          background-color: $light-blue;
          
          .day__date, .day__price {
            color: $white;
          }
        }

        &.other-month {
          color: #aaa;
        }

        &__date {
          font-weight: 400;
          color: $dark-blue;
          font-size: 1.5rem;
          line-height: $line-height;
          text-transform: uppercase;
        }

        &__price{
          margin-top: .5rem;
          color: $light-blue;
          font-size: 1rem;
          font-weight: bold;
        }
      }
    }


    @media (max-width: 768px) {
      &__grid {
        grid-template-columns: repeat(7, 1fr); 
        .day {
          height: 50px; 
          width: 50px; 
          padding: 0.5rem;
          
          
        }
      }
    
      &__header {
        font-size: 1rem;
        padding: 1rem 0.5rem;
        justify-content: space-around;
    
        &__month-year {
          font-size: 1.2em;
        }
    
        &__month {
          font-size: 1rem;
        }
      }
    
      &__days-of-week {
        font-size: 0.8rem;
      }
    }


    .calendar__grid .unavailable .day__price{
      font-size: .7rem;
    }
    
  
    // Add other styles as necessary, such as for buttons, selected days, etc.
}




